import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import Group16Image from '../../images/Group 16.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Login = () => {
    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        errors,
        resetForm
    } = useFormik({
        initialValues: {
            text: '',
            email: '',
            password: '',
            otp:'',
        },
        validationSchema: Yup.object({
            text: Yup.string().matches(/^[A-Za-z0-9]+$/, 'Invalid code').required('Code is Required'),
            email: Yup.string().email('Invalid email address').required('Email is Required'),
            password: Yup.string().max(15, 'Maximum 15 characters or less').required('Password is Required'),
            otp: Yup.string().required('Code is Required'),
        }),

        onSubmit: async (values,  resetForm ) => {
            console.log(values);
            resetForm();
        },
    });

    return (
        <>
            <div className="main-container">
                <div className="container">
                    <div className="head-container text-center"><h1>MALACHI3TEN COLLECTIONS STATEMENT</h1></div>
                    <div className="row mt-3" >
                        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center '>
                            <div className="image"> <img src={Group16Image} alt="" /></div>
                            <button className='button'>Contact Support</button>
                        </div>
                        <div className='col-md-6'>
                            <form className="form" onSubmit={handleSubmit}>
                                <div className='text-center'>
                                    <input
                                        type='text'
                                        name='text'
                                        className='input'
                                        placeholder='Code'
                                        onChange={handleChange}
                                        value={values.text}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='text-center'>
                                    <input
                                        type='email'
                                        name='email'
                                        className='input'
                                        placeholder='Email'
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='text-center'>
                                    <input
                                        type='password'
                                        name='password'
                                        className='input'
                                        placeholder='Password'
                                        onChange={handleChange}
                                        value={values.password}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <button type='submit' className='btnn mt-2'>
                                    Submit
                                </button>
                                <div className='text-center mt-5'>
                                    <input
                                        type='text'
                                        name='otp'
                                        className='input'
                                        placeholder='Enter OTP'
                                        onChange={handleChange}
                                        value={values.otp}
                                        onBlur={handleBlur}
                                    />
                                </div>
                       <Link to="/home">       <button type='submit' className='btnn mt-2'>
                                    Login
                                </button></Link>  
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
