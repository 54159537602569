
  
import React, { useState } from 'react';
import "./Home.css"
import Group16Image from "../../images/Group 16.png"
import chartbarImage from "../../images/chart-bar.png"
import subtractImage from "../../images/Subtract.png"
import vertor9Image from "../../images/Vector 9.png"
import daterangeImage from "../../images/Date_range_light.png"
import { Link } from 'react-router-dom';

const Home = () => {
  const data = [
    {
      Id:1,
      date: "02- FEB-22  ",
      mobile: "+2648180*****",
      idd: "KHVSI45H5",
      ns: "3.60",
      reference:"XG45896 -03B"
    },
    {
      Id: 2,
      date: "02- FEB-22  ",
      mobile: "+2648180*****",
      idd: "KgeVSI45H5",
      ns: "4.60",
      reference: "XG45896 -03B"
    },
    {
      Id: 3,
      date: "02- FEB-22  ",
      mobile: "+266810*****",
      idd: "KHVSI45qerr",
      ns: "3.90",
      reference: "XK4KH896 -03B"
    },
    {
      Id: 4,
      date: "02- FEB-22  ",
      mobile: "+2648180*****",
      idd: "qwEVSI45H5",
      ns: "2.60",
      reference: "SDbG45896 -03B"
    },
    {
      Id: 5,
      date: "02- FEB-22  ",
      mobile: "+264123180*****",
      idd: "KHVSI45H5",
      ns: "3.60",
      reference: "XG45896 -03B"
    }
]
 

  return (
    <>
      <div className="preNav"></div>
      <div className="navBar">
        <div className="imagee"><img src={Group16Image} alt="" /></div>
        <div className="head"><h1>MALACHI_3TEN </h1></div>

  
      </div>
      <div className="row mt-3">
        <div className="col-md-2">
          <div className="statements">
            <div><img src={chartbarImage} alt="" /></div>
            <div>  <p className='pt-3 px-3'>STATEMENTS</p></div>
          </div>
          <div className="logOut">
            <div><img src={subtractImage} alt="" /></div>
            <div>  <p className='pt-3 px-3'><Link to="/">LOG OUT</Link></p></div>
          </div>
          <div className="logOut1">

            <div>  <p className='pt-3 px-3'>VERSION 1.0000</p></div>
          </div>
        </div>
        <div className="col-md-10">
          <div className="section">
            <div className="img"><img src={vertor9Image} alt="" /></div>
            <div className='download-btn'>
              <p>Download Excel</p>
            </div>
          </div>
          <div className='hr'> <hr /></div>

          {/* information  */}
          <div className="main-info">
            <div className="head-info">
              <div><p className='info'>Congregation </p></div>
              <div><span className="span-info">CHURCH</span></div>
              <div><span className="span-info">Contact Person</span></div>
              <div><span className="span-info">Email</span></div>
              <div><span className="span-info">Contact Number</span></div>
              <div><span className="span-info">Product Code</span></div>
              <div><span className="span-info">Country </span></div>
            </div>
            <div className="body-info">
              <div>
                <p className='info'>ELCN - Tanidare Parish</p>
              </div>
              <div><span className="span-info">Evangelical Lutheran Church</span></div>
              <div><span className="span-info">Pastor Kameeta</span></div>
              <div><span className="span-info underline">Kameeta@elcn.com.na</span></div>

           
              <div><span className="span-info">26481825896</span></div>
              <div><span className="span-info">XG45896</span></div>
              <div><span className="span-info">Namibia  </span></div>
            </div>
            <div className="vertical-border"></div>
            <div className="calcu-info ">
              <div><span className="span-info ">Previous Month</span></div>
              <div className='mt-2'><span className="span-info-btn ">3610.00</span></div>
              <div className='mt-2'><span className="span-info ">Current Month to date</span></div>
              <div className='mt-2'><span className="span-info-btn">3610.00</span></div>

              <div class="input-container">
                <img src={daterangeImage} alt="icon" />
                <input type="text" />
              </div>
              <div>
                <button className='display-btn mt-2'>
                  Display
                </button>
              </div>
            </div>

          </div>
          <div className='hr'> <hr /></div>
          {/* user infomation table
         */}
          <span className='history'>HISTORY:</span>
         <div className="table-container">
            <table className="">
              <thead className="">
                <tr className='table-row-head'>
                  <th>Payment Date</th>
                  <th>Mobile #</th>
                  <th>Transaction ID</th>
                  <th>N$/R</th>
                  <th>Subscription Reference</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index} className='table-row-body'>
                    <td>{item.date}</td>
                    <td>{item.mobile}</td>
                    <td>{item.idd}</td>
                    <td>{item.ns}</td>
                    <td className='text-black'>{item.reference}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="address-bar"></div>
          </div>
       

          
        </div>
      </div>
    </>
  );
}

export default Home;
